import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import { OperationsContextType, useOperationsContext } from '../context/Operations.context'
import { CurrencyFormat } from '@utils/Currency.utility'
import { DboardApi } from '@services/index'
import { useEffect, useState } from 'react'


function Cards () {
  const { ctx } = useOperationsContext() as OperationsContextType
  const [totalStock, setTotalStock] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)

  useEffect(() => {
    const fetchTotalStockByOffice = async () => {
      const officeId = ctx.selectedOffice === 'Todos' ? 0 : ctx.officeId.find((office: any) => office.office_name === ctx.selectedOffice)?.office_id
      if (officeId !== undefined) {
        const res = await DboardApi.getTotalStockByOffice(officeId)
        const data = res.data.map((ele: any) => ({ totalStock: ele.total_stock, totalAmount: ele.total_average_cost }))
        if (data.length > 0) {
          setTotalStock(data[0].totalStock)
          setTotalAmount(data[0].totalAmount)
        } else {
          setTotalStock(0)
          setTotalAmount(0)
        }
      }
    }

    fetchTotalStockByOffice().catch(console.error)
  }, [ctx.selectedOffice, ctx.officeId])

  return (
    <Card>
      <CardContent>
        <Grid container direction='row' spacing={2} justifyContent='space-evenly'>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
              <Typography textAlign='center'>
                Stock total por Cantidad
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h4'>
                  {totalStock}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={2} xl={2}>
            <Card sx={{
              background: 'linear-gradient(to left top, #4676b9, #7c3c91)',
              color: '#ffffff',
              height: 150,
              padding: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
              <Typography textAlign='center'>
                Stock total por Monto
              </Typography>
              <Box sx={{ borderBottom: 1, borderBottomColor: 'white', width: '80%', display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h4'>
                  {CurrencyFormat(totalAmount)}
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Cards