import { AppBar, Avatar, Box, ButtonBase, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material'
import { MENU_DASHBOARD, MENU_DASHBOARDBSALE, MENU_PREVENTA, MENU_SEGUROS, MENU_TRADEIN } from '@constants/permission.constants'
import { PrivateRoutes } from '@constants/routes.constants'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  withAuthenticator,
  WithAuthenticatorProps,
} from '@aws-amplify/ui-react';
import { useSelector } from 'react-redux'
import { AppStore, RootState } from 'src/store/store'
import { MouseEvent, useState } from 'react'
import userProfile from '../../assets/img/userProfile.png'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import logoReuse from '../../assets/img/logo-reuse.png'
import * as React from 'react'
import ModalSaveChanges from './components/ModalSaveChanges'

const Header = ({ signOut }: WithAuthenticatorProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const unsavedChanges = useSelector((state: RootState) => state.header.lastClickedButton)
  const [modalGoals, setModalGoals] = useState<{ open: boolean, path: string, loading: boolean }>({ open: false, path: '', loading: false })

  const userState = useSelector((appStore: AppStore) => appStore.user)
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [menuContent, setMenuContent] = useState<null | 'tradein' | 'bsale'>(null)
  const isMenuOpen = Boolean(menuEl)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>, menuType: 'tradein' | 'bsale') => {
    setMenuEl(event.currentTarget)
    setMenuContent(menuType)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuClose = () => {
    setMenuEl(null)
  }

  const handleNavigation = (path: string) => {
    if (path === location.pathname) {
      handleMenuClose()
      return
    }

    if (location.pathname === '/goalsBsale' && unsavedChanges?.booleanValue) {
      setModalGoals({ ...modalGoals, open: true, path })
    } else {
      navigate(path)
    }
    handleMenuClose()
  }

  const menuId = 'tradein-menu'
  const renderMenu = (
    <Menu
      anchorEl={menuEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{ top: '35px' }}
    >
      {menuContent === 'tradein' && [
        <MenuItem onClick={() => handleNavigation(PrivateRoutes.TRADEIN_SALES)} key='sales'>Sales</MenuItem>,
        <MenuItem onClick={() => handleNavigation(PrivateRoutes.TRADEIN_TRAFFIC)} key='traffic'>Traffic</MenuItem>
      ]}
      {menuContent === 'bsale' && [
        <MenuItem onClick={() => handleNavigation(PrivateRoutes.DASHBOARDBSALE)} key='dashboard'>Dashboard</MenuItem>,
        <MenuItem onClick={() => handleNavigation(PrivateRoutes.GOALSBSALE)} key='metas'>Metas</MenuItem>,
        <MenuItem onClick={() => handleNavigation(PrivateRoutes.SALESBSALE)} key='ventas'>Ventas</MenuItem>,
        <MenuItem onClick={() => handleNavigation(PrivateRoutes.OPERACIONES)} key='ventas'>Operaciones</MenuItem>
      ]}
    </Menu>
  )

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed' color='inherit' elevation={1} sx={{ borderBottom: '1px solid rgb(240, 240, 240)', boxShadow: 'rgb(0 0 0 / 15%) 0px 2px 8px' }}>
        <Toolbar>
          <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
            <div className='w-100 d-flex'>
              <div className='nav-menu d-flex flex-column flex-md-row h-100 align-items-lg-center'>
                <img src={logoReuse} title='logo reuse' alt='logo reuse' width='60' height='40' />
                <button
                  type='button'
                  onClick={() => handleNavigation(PrivateRoutes.DASHBOARD)}
                  hidden={!userState.permisos.some((el:any) => el.function === MENU_DASHBOARD)}
                  style={{ background: 'none', cursor: 'pointer' }}
                >
                  Dashboard
                </button>
                <button
                  type='button'
                  onClick={() => handleNavigation(PrivateRoutes.INSURANCE)}
                  style={{ background: 'none', cursor: 'pointer' }}
                  hidden={!userState.permisos.some((el:any) => el.function === MENU_SEGUROS)}
                >
                  Seguros
                </button>
                {/* <button
                  type='button'
                  onClick={() => handleNavigation(PrivateRoutes.CONVERSION_INDICATORS)}
                  hidden={!userState.permisos.some((el:any) => el.function === MENU_INDICADORES)}
                  style={{ background: 'none', cursor: 'pointer' }}
                >
                  Indicadores de conversión
                </button> */}
                <button
                  type='button'
                  onClick={(e) => handleProfileMenuOpen(e, 'tradein')}
                  style={{ background: 'none', cursor: 'pointer' }}
                  hidden={!userState.permisos.some((el:any) => el.function === MENU_TRADEIN)}
                >
                  Trade-in
                </button>
                <button
                  type='button'
                  onClick={() => handleNavigation(PrivateRoutes.PREVENTA)}
                  hidden={!userState.permisos.some((el:any) => el.function === MENU_PREVENTA)}
                  style={{ background: 'none', cursor: 'pointer' }}
                >
                  Preventas
                </button>
                <button
                  type='button' hidden={!userState.permisos.some((el:any) => el.function === MENU_DASHBOARDBSALE)}
                  onClick={(e) => handleProfileMenuOpen(e, 'bsale')}
                  style={{ background: 'none', cursor: 'pointer' }}
                >Dashboard Bsale
                </button>
              </div>
            </div>
          </Box>
          <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
              aria-label='open profile'
              aria-haspopup='true'
              onClick={handleMenu}
            >
              <Stack direction='row' spacing={2} alignItems='center' sx={{ p: 0.5 }}>
                <Avatar alt='profile user' src={userProfile} sx={{ width: 40, height: 40 }} />
                <Typography variant='subtitle1'>{userState.email}</Typography>
              </Stack>
            </ButtonBase>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={signOut}>Salir  <ExitToAppIcon /> </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}

      {modalGoals.open && <ModalSaveChanges modalGoals={modalGoals} setModalGoals={setModalGoals} unsavedChanges={unsavedChanges} userState={userState} />}

    </Box>
  )
}

export default withAuthenticator(Header)
