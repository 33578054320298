import React, { useState } from 'react'
import { Card, CardHeader, Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Box, Button, Alert } from '@mui/material'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import { DboardApi } from '@services/index'
import { OperationsContextType, useOperationsContext } from '@pages/Operations/context/Operations.context'

function MarginSold() {
  const { ctx } = useOperationsContext() as OperationsContextType
  const [hide, setHide] = useState<any>([])
  const [skuNumber, setSkuNumber] = useState<string>('')
  const [selectSBU, setSelectSBU] = useState<string>('')
  const [selectSKU, setSelectSKU] = useState<string>('')
  const [skuData, setSkuData] = useState<any[]>([])
  const [sbuData, setSbuData] = useState<any[]>([])
  const [channelData, setChannelData] = useState<any[]>([])

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  const handleskuNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkuNumber(event.target.value)
  }

  const handleSearch = async () => {
    if (skuNumber) {
      try {
        const res = await DboardApi.getMarginBySku(Number(skuNumber))
        setSkuData(res.data)
        setSbuData([])
        setChannelData([]) 
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    } else {
      setSkuData([])
    }
  }
  const hasNegativeData = () => {
    
    const negativeSbuData = sbuData.some(item => item.margen < 0)
    const negativeSkuData = skuData.some(item => item.average_margin_percentage < 0)
    const negativeChannelData = channelData.some(item => item.margen < 0) 
    return negativeSbuData || negativeSkuData || negativeChannelData; 
  }
  const handleselectSBUChange = (event: SelectChangeEvent<string>) => {
    const selectedSbu = event.target.value
    setSelectSBU(selectedSbu)

    if (selectedSbu) {
      const filteredSbuData = ctx.marginSBU.filter(item => item.sbu === selectedSbu)
      setSbuData(filteredSbuData)
      setSkuData([]) 
      setChannelData([]) 
    } else {
      setSbuData([]) 
    }
  }

  const handleselectSKUChange = (event: SelectChangeEvent<string>) => {
    const selectedSku = event.target.value
    setSelectSKU(selectedSku)

    if (selectedSku) {
      const filteredChannelData = ctx.channelId.filter(item => item.name === selectedSku)
      setChannelData(filteredChannelData) 
      setSbuData([]) 
      setSkuData([]) 
    } else {
      setChannelData([]) 
    }
  }


  const getDataToDisplay = () => {
    if (skuData.length > 0) return skuData
    if (sbuData.length > 0) return sbuData
    if (channelData.length > 0) return channelData
    return [] 
  }

  return (
    <Card sx={{ height: 600 }}>
      <CardHeader
        title={
          <Grid container alignItems="center" justifyContent="space-around">
            <Grid item>
              <Typography variant="h6">Margen Promedio (%)</Typography>
            </Grid>
          </Grid>
        }
      />



      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-evenly' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Número de SKU"
            value={skuNumber}
            onChange={handleskuNumberChange}
            variant="outlined"
            size="small"
            sx={{ m: 1, width: 200 }}
          />
          <Button variant="contained" color="primary" onClick={handleSearch} sx={{ m: 1 }}>
            Buscar
          </Button>
        </Box>

        <FormControl sx={{ m: 1, width: 200 }}>
          <InputLabel>SBU</InputLabel>
          <Select
            value={selectSBU}
            onChange={handleselectSBUChange}
            size="small"
          >
            {ctx.marginSBU
              .filter((item: any) => item.sbu && item.margen !== null)
              .map((item: any) => (
                <MenuItem key={item.id} value={item.sbu}>
                  {item.sbu}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 200 }}>
          <InputLabel>Channel</InputLabel>
          <Select
            value={selectSKU}
            onChange={handleselectSKUChange}
            size="small"
          >
            {ctx.channelId
              .filter((item: any) => item.name && item.margen !== null)
              .map((item: any) => (
                <MenuItem key={item.id_channel} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

  
      {getDataToDisplay().length > 0 ? (
        <ResponsiveContainer width="100%" height="65%">
          <ComposedChart
            data={getDataToDisplay()}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 50
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="product_type" />
            <YAxis 
              domain={['auto', 'auto']}  
              reversed={hasNegativeData()}  
            />
            <Tooltip formatter={(value: any, name: any, props: any) => value} />
            <Legend wrapperStyle={{ cursor: 'pointer' }} onClick={handleLenged} />

            {skuData.length > 0 && (
              <Bar dataKey="average_margin_percentage" name="Margen Promedio SKU (%)" legendType="circle" fill="#413ea0" barSize={30}>
                <LabelList dataKey="average_margin_percentage" position="top" formatter={(value: any) => value.toFixed(2)} fontSize={12} />
              </Bar>
            )}

            {sbuData.length > 0 && (
              <Bar dataKey="margen" name="Margen Promedio SBU (%)" legendType="circle" fill="#ff7300" barSize={30}>
                <LabelList dataKey="margen" position="top" formatter={(value: any) => value.toFixed(2)} fontSize={12} />
              </Bar>
            )}

            {channelData.length > 0 && (
              <Bar dataKey="margen" name="Margen Promedio Channel (%)" legendType="circle" fill="#00bcd4" barSize={30}>
                <LabelList dataKey="margen" position="top" formatter={(value: any) => value.toFixed(2)} fontSize={12} />
              </Bar>
            )}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <Box sx={{ padding: 2, textAlign: 'center' }}>
        <Alert severity="info" sx={{ marginBottom: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          Filtrar para visualizar los datos correspondientes
        </Alert>
      </Box>
      )}
    </Card>
  )
}

export default MarginSold