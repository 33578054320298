import React, { useState } from 'react'
import { Box, Card, CardHeader, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,

} from 'recharts'
import { OperationsContextType, useOperationsContext } from '@pages/Operations/context/Operations.context'
import { formatNumber } from '@utils/dashboardBsale'


function InventoryForDays() {
  const { ctx } = useOperationsContext() as OperationsContextType
  const [hide, setHide] = useState<any>([])
  const [selectedProducts, setSelectedProducts] = useState<string[]>([])

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  const handleProductChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target
    setSelectedProducts(typeof value === 'string' ? value.split(',') : value)
  }

  const filteredData = ctx.daysInventory.filter((item: any) =>
    selectedProducts.length === 0 || selectedProducts.includes(item.tipoProducto)
  )

  return (
    <Card sx={{ height: 700 }}>
       <CardHeader
        title={
          <Grid container alignItems='center' justifyContent='space-around'>
            <Grid item>
            <Typography variant='h6'>Días de Inventario por Venta</Typography>
            </Grid>
          </Grid>
        }
      />
      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'end' }}>
   
        <Box>
          <FormControl fullWidth sx={{ m: 1, width: 300 }}>
            <InputLabel>Tipo Producto</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              size='small'
              value={selectedProducts}
              onChange={handleProductChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {ctx.daysInventory.map((item: any) => (
                <MenuItem key={item.id} value={item.tipoProducto}>
                  <Checkbox checked={selectedProducts.includes(item.tipoProducto)} />
                  <ListItemText primary={item.tipoProducto} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          width={500}
          height={400}
          data={filteredData}
          margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='tipoProducto' />
          <YAxis tickFormatter={formatNumber} />
          <Tooltip formatter={(value: any, name: any, props: any) => {
        if (name === 'Días') {
          return [value, name];
        }
        return [value, name];
          }} />
          <Legend
        wrapperStyle={{ cursor: 'pointer' }}
        onClick={handleLenged}
          />
          <Bar dataKey='daysInvetory' name='Dias' legendType='circle' fill='#413ea0' barSize={30}>
          </Bar>
        
        </ComposedChart>
      </ResponsiveContainer>
        </Card>
      )
    }

export default InventoryForDays