import React, { useEffect, useState } from 'react'
import { Card, CardHeader, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import { OperationsContextType, useOperationsContext } from '@pages/Operations/context/Operations.context'


function Returns() {
  const { ctx } = useOperationsContext() as OperationsContextType;
  const [hide, setHide] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [selectedOffice, setSelectedOffice] = useState<string>("");

  useEffect(() => {
   
    if (ctx && ctx.returnOffice && ctx.returnOffice.length > 0) {
      const officeData = ctx.returnOffice;  
      setData(officeData);
      setFilteredData(officeData); 
    }
  }, [ctx]);

  const handleOfficeChange = (event: any) => {
    const selected = event.target.value;
    setSelectedOffice(selected);

    if (selected) {
      const filtered = data.filter((item: any) => item.office === selected);
      setFilteredData(filtered);
    } else {
      setFilteredData(data); 
    }
  };

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const updatedHide = hide.filter((ele: any) => ele !== e.id);
      setHide(updatedHide);
    } else {
      setHide([...hide, e.id]);
    }
  };
  const formatInMillions = (value: number) => {
    return (value / 1000000).toFixed(2) + 'M'
  }

  const officeOptions = data.map((item: any) => item.office);

  return (
    <Card sx={{ height: 600 }}>
      <CardHeader
        title={
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="h6">Devolucion por Cantidad y Monto</Typography>
            </Grid>
            <Grid item container spacing={2} justifyContent="flex-end" alignItems="center">
              <Grid item>
                <FormControl sx={{ width: 200 }} size="small">
                  <InputLabel>Office</InputLabel>
                  <Select key={selectedOffice} value={selectedOffice} onChange={handleOfficeChange}>
                 
                    {officeOptions.map((office: any) => (
                      <MenuItem key={office} value={office}>
                        {office}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
             
            </Grid>
          </Grid>
        }
      />

      <ResponsiveContainer width="100%" height="80%">
        <ComposedChart margin={{ top: 20, right: 20, bottom: 20, left: 50 }} data={filteredData}>
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis  dataKey="year" />
          <YAxis  tickFormatter={formatInMillions} />
          <Tooltip
            formatter={(value: number, name, props) => (
              <div>
                <div>{value}</div>
               
              </div>
            )}
          />
          <Legend wrapperStyle={{ cursor: 'pointer' }} onClick={handleLenged} />
          <Bar dataKey="total_devoluciones" name="Total Devolucion" legendType="circle" fill="#413ea0" barSize={30}>
            <LabelList dataKey="total_devoluciones" position="top" fontSize={12}  formatter={formatInMillions} />
          </Bar>
          <Bar dataKey="total_monto_devoluciones" name="Total Devolucion por Monto" legendType="circle" fill="#ff7300" barSize={30}>
            <LabelList dataKey="total_monto_devoluciones" position="top" fontSize={12}  formatter={formatInMillions} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  );
}

export default Returns;