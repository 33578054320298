import { Box, Button, Input, Paper, Popper, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { GoalsTableProps } from '@models/goalsBsale'

type PopperType = { anchorEl: null | HTMLElement, open: boolean, week: number, dayOfTheWeek: number, day: number }
interface PopperComponentProps extends GoalsTableProps {
  popper: PopperType
  setPopper: Dispatch<SetStateAction<PopperType>>
}

const daysOfWeek = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]

function PopperDayOfTheWeek({
  popper, setPopper, state, setState, dailyWeights, onSaveWeights
}: PopperComponentProps & {
  dailyWeights: { [key: number]: number }
  onSaveWeights: (newWeights: { [key: number]: number }) => void
}) {
  const [weights, setWeights] = useState<{ [key: number]: number }>(dailyWeights)

  const handlePopper = (e: any) => {
    const anchorEl: any = popper.anchorEl ? null : e.currentTarget
    const open = Boolean(anchorEl)
    setPopper({ open, anchorEl, week: popper.week, dayOfTheWeek: popper.dayOfTheWeek, day: popper.day })
  }

  const handleClosePopper = () => {
    setPopper({ ...popper, open: false, anchorEl: null })
  }

  const handleSave = () => {
    const copyWeeks = [...state.weeks]

    copyWeeks.forEach((week) => {
      if (week.weeklyGoal === undefined) {
        const totalWeekGoal = week.days.reduce((acc, day) => day.newGoal === 0 ? acc + day.goal : acc, 0)
        const activeWeights = week.days.reduce((acc, day, index) => {
          if (day.newGoal === 0) acc[index] = weights[index] || 1
          return acc
        }, {} as { [key: number]: number })
        
        const totalActiveWeights = Object.values(activeWeights).reduce((sum, weight) => sum + weight, 0)

        week.days.forEach((day, index) => {
          if (day.newGoal === 0) {
            const adjustedWeight = (activeWeights[index] / totalActiveWeights) * 100
            day.goal = totalWeekGoal * adjustedWeight / 100
          }
        })
      }
    })

    onSaveWeights(weights) // Actualizar los dailyWeights en el componente principal
    setState(prevState => ({ ...prevState, weeks: copyWeeks, dailyWeights: { ...weights } }))
    handleClosePopper() // Cerrar el Popper después de guardar
  }

  const hasZeroWeight = Object.values(weights).some(weight => weight === 0)
  const totalWeight = Object.values(weights).reduce((sum, weight) => sum + weight, 0)
  const isSaveDisabled = hasZeroWeight || totalWeight !== 100

  return (
    <Popper open={popper.open} anchorEl={popper.anchorEl} placement="bottom-end">
      <Paper sx={{ padding: 2, display: 'flex', gap: 2, flexDirection: 'column' }} onClick={(event) => event.stopPropagation()}>
        <Typography variant="body2">Asignar ponderación para cada día (Total debe ser 100):</Typography>
        {daysOfWeek.map((dayName, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant='body2'>{dayName}:</Typography>
            <Input
              value={weights[index] || ''}
              size="small"
              sx={{ width: 70 }}
              type="number"
              inputProps={{ min: 1, max: 100 }}
              placeholder={`${dailyWeights[index] || 1}`} // Usar el valor previo como placeholder
              onChange={(e) => {
                const value = Number(e.target.value)
                if (value >= 1 && value <= 100) { // Validar que el valor esté entre 1 y 100
                  setWeights(prevWeights => ({ ...prevWeights, [index]: value }))
                }
              }}
            />
          </Box>
        ))}
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleSave}
            disabled={isSaveDisabled} // Deshabilitar si hay algún día con ponderación 0 o el total no es 100
          >
            Aceptar
          </Button>
          <Button variant="outlined" size="small" onClick={(e) => handlePopper(e)}>Cancelar</Button>
        </Box>
        {isSaveDisabled && (
          <Typography variant="caption" color="error">
            La suma de las ponderaciones debe ser exactamente 100 y cada día debe tener una ponderación mayor a 0.
          </Typography>
        )}
      </Paper>
    </Popper>
  )
}

export default PopperDayOfTheWeek
