import React, { useState } from 'react'
import { Card, CardHeader, Grid, Typography} from '@mui/material'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import { OperationsContextType, useOperationsContext } from '@pages/Operations/context/Operations.context'

 


function TotalInventoryStore() {
  const { ctx } = useOperationsContext() as OperationsContextType
  const [hide, setHide] = useState<any>([])

  
  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  const getFilteredData = () => {
    if (ctx.selectedOffice && ctx.selectedOffice !== 'Todos') {
      return ctx.officeId.filter((item: any) => item.office_name === ctx.selectedOffice)
    }
    return ctx.officeId
  }

  const getGroupedData = () => {
    const dataMap: { [key: string]: { product_type: string, quantity: number } } = {}
    getFilteredData().forEach((item: any) => {
      if (dataMap[item.product_type]) {
        dataMap[item.product_type].quantity += item.quantity
      } else {
        dataMap[item.product_type] = { product_type: item.product_type, quantity: item.quantity }
      }
    })
    return Object.values(dataMap)
  }

  return (
    <Card sx={{ height: 400 }}>
      <CardHeader
        title={
          <Grid container alignItems='center' justifyContent='space-around'>
            <Grid item>
              <Typography variant='h6'>Inventario por Bodega (Cantidad)</Typography>
            </Grid>
          </Grid>
        }
      />
      <ResponsiveContainer width='100%' height='80%'>
        <ComposedChart
          data={getGroupedData()}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='product_type' />
          <YAxis />
          <Tooltip formatter={(value: any, name: any, props: any) => value} />
          <Legend
            wrapperStyle={{ cursor: 'pointer' }}
            onClick={handleLenged}
          />
          <Bar dataKey='quantity' name={`${ctx.selectedOffice === undefined ? 'Todos ' : ctx.selectedOffice}`} legendType='circle' fill='#413ea0' barSize={30}>
            <LabelList dataKey='quantity' position='top' formatter={(value: any) => value} fontSize={12} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default TotalInventoryStore

