
import {  Card, CardContent, FormControl, Grid, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material'
import { useState } from 'react'
import { useOperationsContext, OperationsContextType } from '../context/Operations.context'

function Filter () {
  const { ctx, saveCtx } = useOperationsContext() as OperationsContextType
  const [selectedOffice, setSelectedOffice] = useState<string>('Todos')

  const handleOfficeChange = (event: SelectChangeEvent<string>) => {
    const office = event.target.value
    setSelectedOffice(office)
    saveCtx({ ...ctx, selectedOffice: office })
  }

  const getUniqueOffices = () => {
    const officeMap: { [key: string]: string } = {}
    ctx.officeId.forEach((item: any) => {
      if (!officeMap[item.office_name]) {
        officeMap[item.office_name] = item.office_name
      }
    })
    return ['Todos', ...Object.values(officeMap)]
  }

  return (
    <Card>
      <CardContent className='filters'>
      <Typography variant='h1' color='textSecondary' className='mb-4' sx={{ color: '#000', fontWeight: '500px', fontSize: '1rem',marginBottom: '1rem' }}>
              <b>Filtrar por Bodega</b>
            </Typography>
        <Grid container direction='row' spacing={2} alignItems='center'>
          <Grid item xs={12} md={12} lg={1.5} xl={1.5}>
            <FormControl fullWidth>
              <Select
                key='office-select'
                placeholder='Bodega'
                value={selectedOffice}
                onChange={handleOfficeChange}
                size='small'
                sx={{ '& .MuiSelect-select': { color: 'black' } }}
              >
                {getUniqueOffices().map((office) => (
                  <MenuItem key={office} value={office}>
                    {office}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default Filter