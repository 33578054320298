import React, { useState } from 'react'
import { Card, CardHeader, Grid, Typography } from '@mui/material'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
} from 'recharts'
import { OperationsContextType, useOperationsContext } from '@pages/Operations/context/Operations.context'


function Obsolescencia() {
  const { ctx } = useOperationsContext() as OperationsContextType
  const [hide, setHide] = useState<any>([])

  const handleLenged = (e: any) => {
    if (hide.includes(e.id)) {
      const data = hide.filter((ele: any) => ele !== e.id)
      setHide(data)
    } else {
      setHide([...hide, e.id])
    }
  }

  const totalQuantity = ctx.skuPruchase.reduce((acc: number, item: any) => acc + 1, 0);
  const totalQuantity30days = ctx.skuPurchase30.reduce((acc: any, item: any) => acc + 1, 0);

  
  const skuPurchaseData = [{ type: 'Sin compra x 60 dias', quantity: totalQuantity }];
  const skuPurchase30daysData = [{ type: 'Sin venta ultimos 30 dias', quantityDays: totalQuantity30days }];
  
  return (
    <Card sx={{ height: 400 }}>
      <CardHeader
        title={
          <Grid container alignItems="center" justifyContent="space-around">
            <Grid item>
              <Typography variant="h6">Obsolescencia de SKUs</Typography>
            </Grid>
          </Grid>
        }
      />
      <ResponsiveContainer width="100%" height="80%">
        <ComposedChart
          data={[...skuPurchaseData, ...skuPurchase30daysData]}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 50
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="type" /> 
          <YAxis />
          <Tooltip formatter={(value: any, name: any, props: any) => [`${value}`, 'Cantidad']} />
          <Legend
            wrapperStyle={{ cursor: 'pointer' }}
            onClick={handleLenged}
          />
          <Bar dataKey="quantity" name="Cantidad de SKUs" legendType="circle" fill="#413ea0" barSize={30}>
            <LabelList dataKey="quantity" position="top" formatter={(value: any) => value.toFixed(2)} fontSize={12} />
          </Bar>
          <Bar dataKey="quantityDays" name="Cantidad de SKUs sin venta en 30 días" legendType="circle" fill="#ff7300" barSize={30}>
            <LabelList dataKey="quantityDays" position="top" formatter={(value: any) => value.toFixed(2)} fontSize={12} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  )
}

export default Obsolescencia